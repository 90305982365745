<template>
  <div>
    <b-table-simple class="p-table" striped>
      <thead>
        <tr>
          <td class="td-header">Número</td>
          <td class="td-header">Produto</td>
          <td class="td-header">Unidade de medida</td>
          <td class="td-header">Qtde. atual</td>
          <td class="td-header">Qtde. mínima</td>
          <td class="td-header">Lotes</td>
          <td class="td-header"></td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="product in products" :key="product.id">

          <td class="td-body">
            <div class="number">{{ normalizeProductNumber(product.number) }}</div>
          </td>
          <td class="td-body">{{ product.name }}</td>
          <td class="td-body td-simple">{{ product.measure_unit.name }}</td>
          <td class="td-body td-simple">
            <div class="quantity">
              <div v-bind:class="{ danger: product.product_balance && product.product_balance.balance < product.minimum_quantity, warning: product.product_balance && product.product_balance.balance === product.minimum_quantity }">
                {{ product.product_balance ? normalizeProductNumber(product.product_balance.balance, 2) : 0}}
              </div>
              <span :id="`product_${product.id}`" v-if="isBelowMinimum(product)" class="icon-box"><v-info-danger class="icon stroke"  /></span>
              <span :id="`product_${product.id}`" v-if="isEqualMinimum(product)" class="icon-box"><v-info-warning class="icon stroke"  /></span>
              <b-tooltip :target="`product_${product.id}`" placement="bottom">
                {{ isBelowMinimum(product) ? 'Abaixo da quantidade mínima' : '' }}
                {{ isEqualMinimum(product) ? 'Igual a quantidade mínima' : '' }}
              </b-tooltip>
            </div>
          </td>
          <td class="td-body td-simple">{{ product.minimum_quantity }}</td>
          <td class="td-body td-simple">{{ product.batch_control ? 'Sim' : 'Não' }}</td>
          <td class="td-body td-simple">
            <div v-if="userHasPermission('EstProd3') || userHasPermission('EstProd4')" class="more-with-menu">
              <v-more-vertical class="more-icon" />
              <div class="menu">
                <b-button v-can="'EstProd3'" variant="link" @click="onEdit(product)"
                  >Editar</b-button
                >
                <b-button
                  v-can="'EstProd4'"
                  variant="link remove mb-0"
                  @click="onDeleteClick(product)"
                  >Excluir</b-button
                >
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </b-table-simple>

    <v-empty-table-results
      emptyMessage="Cadastre um produto para visualizá-lo aqui."
      :loading="loading"
      :length="products ? products.length : 0"
      :hasFilter="hasFilter"
    />

    <v-delete-modal
      type="produto"
      :description="toDelete ? toDelete.name : ''"
      :confirmedDelete="confirmedDelete"
    />
  </div>
</template>
<script>
import { userHasPermission } from '@/utils/localStorageManager'
import MoreVertical from '@/assets/icons/more-vertical.svg'
import InfoWarning from '@/assets/icons/info-circle-warning.svg'
import InfoDanger from '@/assets/icons/info-circle-danger.svg'
import { normalizeProductNumber } from '@/utils/product-helper'
import DeleteModal from '@/components/General/DeleteModal'
import EmptyTableResults from '@/components/General/EmptyTableResults'
export default {
  props: {
    loading: Boolean,
    hasFilter: { validate: v => true },
    products: { type: Array, required: true },
    onEdit: Function,
    onDelete: Function,
  },
  components: {
   'v-more-vertical': MoreVertical,
   'v-info-warning': InfoWarning,
   'v-info-danger': InfoDanger,
   'v-delete-modal': DeleteModal,
   'v-empty-table-results': EmptyTableResults,
  },
  data() {
    return {
      toDelete: null,
    }
  },
  methods: {
    userHasPermission,
    normalizeProductNumber,
    onDeleteClick(product) {
      this.toDelete = product;
      this.$bvModal.show('delete-modal');
    },
    confirmedDelete(confirm) {
      if (confirm) {
        this.onDelete(this.toDelete)
      }
      this.toDelete = null;
      this.$bvModal.hide('delete-modal');
    },
    isBelowMinimum(product) {
      return product.product_balance && product.product_balance.balance < product.minimum_quantity;
    },
    isEqualMinimum(product) {
      return product.product_balance && product.product_balance.balance === product.minimum_quantity;
    }
  }
}
</script>
<style lang="scss" scoped>
  .p-table {
    width: 100%;
    text-align: left;
    margin: 20px 0;

    .td-header {
      font-weight: 700;
      font-size: 14px;
      color: var(--type-active);
      border: none;
      padding: 20px 10px;
    }
    .td-body {
      padding: 20px 10px;
      border-top: 1px dashed var(--neutral-300);
      font-weight: 600;
      font-size: 16px;
      color: var(--type-active);
    }
    .td-simple {
      font-weight: normal !important;
    }
    .number {
      font-family: 'Red Hat Display';
      font-weight: 700;
      color: var(--neutral-500);
    }
    .icon {
      width: 18px;
      height: 18px;
      margin: -4px 0 0 5px;
    }
    .edit-text {
      font-size: 16px;
      font-weight: 600;
      color: var(--blue-500);
    }
    .delete-text {
      font-size: 16px;
      font-weight: 600;
      color: var(--states-error);
    }
    .quantity {
      display: inline-flex;
      align-items: center;
    }
    .danger {
      color: var(--states-error);
    }
    .warning {
      color: var(--orange);
    }
  }
  .more-with-menu {
    position: relative;
    display: inline-block;

    .more-icon {
      height: 24px;
      stroke: var(--neutral-500);
      transition: all 0.5s;
      cursor: pointer;
    }

    .menu {
      position: absolute;
      top: 28px;
      right: 0;
      background: var(--neutral-000);
      border: 1px solid var(--neutral-200);
      box-sizing: border-box;
      box-shadow: 0px 4px 4px rgba(12, 29, 89, 0.1), 0px 4px 10px -8px rgba(12, 29, 89, 0.2);
      border-radius: 8px;
      opacity: 0;
      transition: all 0.5s;
      flex-direction: column;
      align-items: start;
      display: none;

      .mtb-12 {
        margin: 12px 0;
      }

      .btn {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;

        &.remove {
          color: var(--states-error);
        }

        &.btn-link {
          padding: 12px 80px 12px 16px !important;
        }
      }
    }

    &:hover {
      .more-icon {
        transform: rotate(90deg);
      }

      .menu {
        opacity: 1;
        display: flex;
        z-index: 10;
      }
    }
  }
</style>
